<template>
  <div class="col-12 col-lg-7 c">
    <div class="card">
      <div class="card-header">
        <h4 class="text-center">
          <i class="fa fa-user-plus"></i>
          إضافة مشرف جديد
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <h5 for="">الإسم</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="name"
          />
        </div>
        <div class="form-group">
          <h5 for="">إسم المستخدم</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="username"
          />
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="password"
          />
        </div>
        <div class="form-group">
          <h5 for="">القسم</h5>
          <select class="form-control" name="" v-model="section_id" id="">
            <template v-for="section in sections">
              <option :value="section._id" :key="section._id">
                {{ section.title }}
              </option>
            </template>
          </select>
        </div>
        <div class="col-12 g">
          <div class="card card-body border">
            <div class="form-check">
              <h5 class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="all_rules"
                  :value="true"
                />
                مسموح له بكامل الصلاحيات
              </h5>
            </div>
            <div v-if="!all_rules">
              <hr />
              <div v-for="rule in rulesArray" :key="rule.title">
                <template v-if="rule.title">
                  <div class="g border" style="margin-bottom: 5px">
                    <div class="form-check">
                      <h5 class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="rulesArray[rule.code].allowed"
                          :value="true"
                        />
                        {{ rule.title }}
                      </h5>
                    </div>
                    <div v-if="rulesArray[rule.code].allowed">
                      <div class="row g">
                        <div class="col-12 col-lg-3">
                          <div class="form-check">
                            <h5 class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                v-model="rulesArray[rule.code].add"
                                :value="true"
                              />
                              إضافة
                            </h5>
                          </div>
                        </div>
                        <div class="col-12 col-lg-3">
                          <div class="form-check">
                            <h5 class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                v-model="rulesArray[rule.code].view"
                                :value="true"
                              />
                              عرض
                            </h5>
                          </div>
                        </div>
                        <div class="col-12 col-lg-3">
                          <div class="form-check">
                            <h5 class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                v-model="rulesArray[rule.code].edit"
                                :value="true"
                              />
                              تعديل
                            </h5>
                          </div>
                        </div>
                        <div class="col-12 col-lg-3">
                          <div class="form-check">
                            <h5 class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                v-model="rulesArray[rule.code].delete"
                                :value="true"
                              />
                              حذف
                            </h5>
                          </div>
                        </div>
                        <div
                          class="col-12 g"
                          v-if="
                            rulesArray[rule.code].view && rule.code == 'bus'
                          "
                        >
                          <br />
                          <strong class="g">الباصات المسموحة:</strong>
                          <br />
                          <br />
                          <ul>
                            <li v-for="bus in busses" :key="bus._id">
                              <div class="form-check">
                                <h5 class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name=""
                                    id=""
                                    v-model="rulesArray['allowed_busses']"
                                    :value="bus._id"
                                  />
                                  {{ bus.title }}
                                </h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="col-12 g"
                          v-if="
                            rulesArray[rule.code].view && rule.code == 'groups'
                          "
                        >
                          <br />
                          <strong class="g">خطوط السير المسموحة:</strong>
                          <br />
                          <br />
                          <ul>
                            <li v-for="group in groups" :key="group._id">
                              <div class="form-check">
                                <h5 class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name=""
                                    id=""
                                    v-model="rulesArray['allowed_groups']"
                                    :value="group._id"
                                  />
                                  {{ group.title }}
                                </h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="add()">
            إضافة المشرف
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    if (checkPer(this, "admins", "add")) {
      var g = this;
      $.post(api + "/admin/groups/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.groups = JSON.parse(r).response;
      });
      $.post(api + "/admin/bus/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.busses = JSON.parse(r).response;
      });
      $.post(api + "/admin/sections/list", {
        jwt: g.user.jwt,
      }).then(function (r) {
        g.sections = JSON.parse(r).response;
      });
    }
  },
  data() {
    return {
      section_id: null,
      user: JSON.parse(localStorage.getItem("user")),
      rulesArray: {
        allowed_busses: [],
        allowed_groups: [],
        admins: {
          code: "admins",
          title: "التحكم في مشرفين الموقع",
          edit: true,
          view: true,
          add: true,
          delete: true,
          allowed: false,
        },
        admins_bus: {
          code: "admins_bus",
          title: "التحكم في مشرفين الباصات",
          edit: true,
          view: true,
          add: true,
          delete: true,
          allowed: false,
        },
        students: {
          code: "students",
          title: "التحكم في الطلاب",
          edit: true,
          view: true,
          add: true,
          delete: true,
          allowed: false,
        },
        parents: {
          code: "parents",
          title: "التحكم في أولياء الأمور",
          edit: true,
          view: true,
          add: true,
          delete: true,
          allowed: false,
        },
        groups: {
          code: "groups",
          title: "التحكم في خطوط السير",
          edit: true,
          view: true,
          add: true,
          delete: true,
          allowed: false,
        },
        bus: {
          code: "bus",
          title: "التحكم في الباصات",
          edit: true,
          view: true,
          add: true,
          delete: true,
          allowed: false,
        },
      },
      name: null,
      password: null,
      username: null,
      all_rules: false,
      rules: [],
      busses: [],
      groups: [],
      sections: [],
    };
  },
  methods: {
    add() {
      var g = this;
      if (!g.username || !g.password || !g.name) {
        alert("هناك حقول مطلوبة");
      } else {
        $.post(api + "/admin/admins/add", {
          jwt: g.user.jwt,
          name: g.name,
          section_id: g.section_id,
          username: g.username,
          password: g.password,
          all_rules: g.all_rules,
          rules: g.rulesArray,
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              alert("تم اضافة المشرف بنجاح");
              g.$router.push("/admins/list");
            } else {
              alert(r.response);
            }
          })
          .catch(function (r) {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>

<style>
</style>